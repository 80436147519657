import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import EconomicsBanner from "../components/economicsBanner";

class EconomicsWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<EconomicsBanner />}>
                <SEO title="Economic Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box">
                                    <div className="img-box__img">
                                        <img alt="education" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/economics.png" className="img--bg m-b-10" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="text-medium"><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/okuafo-pa-small.png" width="40" /> Economics</h2>
                                <h5 style={{marginLeft: 50, marginBottom: 24}}>(Development, entrepreneurship, and sustainable employment inclusive of communication, technology and innovation)</h5>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li>Raise awareness in business, government and employment sectors to address hiring policies, pay inequities/ wage gap, workplace discrimination &amp; unconscious bias</li>
                                    <li>Support job creation in communities with traditionally high unemployment (rural areas)</li>
                                    <li>Systemic racial discrimination is prevalent throughout the province; government must commit to lead the change </li>
                                    <li>Black-owned businesses not being able to access the government procurement supply chain process</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li>Institute targeted policies &amp; equity hiring programs</li>
                                    <li>Develop a cultural awareness campaign for employers – Employers need to see diverse faces in all aspects of the province communication strategies (i.e. tourism) Corporations like Michelin have gone out of their way to promote diversity as more than just a word. What tangible initiatives has the province done?</li>
                                    <li>Reward those companies and businesses that truly buy into a building a diverse culture while having an ongoing review of provincial contracts – hiring and retention (include penalty for government contracts not meeting standards)</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default EconomicsWorkingGroup